// general colors
$white: #FFFFFF;
$black: #000000;
$dark-gray: #2E2E2E;
$text-color: #434343;
$light-gray: #EAEAEA;
$bg-light-gray: #F8F8F8;
// bs overload
$brand-primary: #141927;
$brand-secondary: #1D2744;
$brand-tertiary: #D5DD07;

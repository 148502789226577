html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @include bg-color($brand-primary);
  background-image: url('/img/main-bg.jpg');
  background-size: cover;
}
.main-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  min-height: 50rem;
  position: relative;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}
.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 16.0rem;
  width: 100%;
  margin: auto;
  z-index: 1;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: 5.0rem;
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

header{
  flex: 1;
  padding-top: 1.0rem;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
    padding-left: 2.0rem;
  }
  .logo-link{
    z-index: 2;
    // display: block;
  }
  .logo{
    max-width: 20rem;
    height: auto;
  }
}
.highlight{
  flex: 7;
  z-index: 2;
  @media (min-width: $screen-sm-min) {
    flex: 6;
    padding-top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .features{
    img{
      max-width: 25.0rem;
      @media (min-width: 321px) {
        max-width: 28.0rem;
      }
      @media (min-width: $screen-sm-min) {
        max-width: 30.0rem;
      }
    }
  }
  color: $white;
  h1{
    font-size: 1.8rem;
    line-height: 1.4em;
    font-weight: $semi-bold;
    text-align: center;
    @media (min-width: 321px) {
      padding-top: 2.0rem;
      font-size: 2.0rem;
    }
    @media (min-width: $screen-sm-min) {
      padding-top: 0rem;
      text-align: left;
    }
    @media (min-width: $screen-md-min) {
      font-size: 3.0rem;
    }
    strong{
      font-weight: $bold;
    }
  }
  h2{
    font-weight: $semi-bold;
    font-size: 1.4rem;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
  }
  $p: 5.0rem;
  .feature-list{
    text-align: center;
    color: #FBFBFB;
    font-size: 3.5rem;
    @media (min-width: $screen-sm-min) {
      padding-top: 1.0rem;
      font-size: 3.0rem;
    }
  }
  @include link('a.btn-info'){
    border: 1px solid $white;
    @include bg-color(transparent);
    padding: 6px 10px;
    border-radius: 3px 3px 3px 3px;
    font-size: 1.4rem;
  }
  .feature-actions{
    text-align: center;
  }
}

footer{
  position: relative;
  $footer-color: darken($brand-primary, 6%);
  @include bg-color($white);
  font-weight: $semi-bold;
  color: $footer-color;
  flex: 1;
  font-size: 1.0rem;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .container{
    z-index: 9999;
  }
  .footer-column{
    z-index: 9999;
  }
  @include link('a'){
    color: $footer-color;
  }
  .social{
    font-size: 2.0rem;
    text-align: center;
    a{
      color: $footer-color;
      margin-left: 1.5rem;
    }
  }
}

body, html {
    height: 100%;
    width: 100%;
    font-family: "Open Sans", "Roboto", monospace;
}
.btn{
  &.btn-default{
    @include bg-color($brand-secondary);
    color: $white;
    border-color: darken($brand-secondary, 10%);
  }
  &.btn-primary{
    @include bg-color($brand-primary);
    color: $white;
    border-color: darken($brand-primary, 10%);
  }
}
